<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Usuários Cadastrados</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>

        <v-col cols="12">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn color="white" class="float-left" small @click="obterInformacoes">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="white" class="mx-3" small @click="modalFiltro = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>

                  <v-chip
                      v-for="(value, key) in filtros"
                      :key="key"
                      v-if="(value != null && value.length > 0)"
                      class="mx-3"
                      close
                      color="orange"
                      label
                      outlined
                      @click:close="filtros[key] = null"
                  > {{ key | primeiraLetraMaiuscula }}: {{ value }}
                  </v-chip>
                  <v-btn class="ml-3 float-right" color="orange" to="/usuarios/cadastrar" dark small>
                    <v-icon>mdi-plus</v-icon>
                    Novo Usuario
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="permissaoPagina">
                <v-col cols="12">
                  <v-data-table
                      :headers="headersTabela"
                      :items="dadosTabela"
                      locale="pt"
                  >
                    <template v-slot:item.habilitado="{ item }">
									<span :class="obterClasseHabilitado(item)">
										{{ obterDescricaoHabilitado(item) }}
									</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-tooltip bottom content-class="pa-0 px-2" v-for="(btnAcao, key) in btnsAcoes" :key="key">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="btnAcao.acao(item)">
                            <v-icon
                                v-text="btnAcao.icone"
                                :color="btnAcao.cor"
                                v-bind="attrs"
                                v-on="on"
                                medium
                            />
                          </v-btn>
                        </template>

                        <span class="font-size-13">{{ btnAcao.tooltip }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <dialog-filtro
            ref="DialogFiltro"
            :aberto="modalFiltro"
            @fechar="modalFiltro = false"
            @filtrar="filtrar"
        />

        <dialog-detalhes ref="dialogDetalhes"/>

        <dialog-confirmacao ref="dialogConfirmacao" v-on:confirmou="excluirUsuario()"/>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import DialogConfirmacao from "../../components/DialogConfirmacao";
import DialogDetalhes from "../../components/usuarios/DialogDetalhes";
import mixinGeral from "../../mixin/geral";
import usuariosServices from "../../services/usuarios";
import DialogFiltro from "@/components/usuarios/DialogFiltro";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "Index",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
    DialogDetalhes,
    DialogConfirmacao,
    DialogFiltro
  },
  data() {
    return {
      modalFiltro: false,
      idUsuarioExclusao: null,
      filtros: {
        nome: null,
        login: null,
        empresa: null,
      },
    };
  },
  computed: {
    permissaoPagina() {
      return this.permissaoMaster || this.permissaoAdmin;
    },
    headersTabela() {
      return [
        {
          text: "Nome",
          value: "nome",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Usuário",
          value: "usuario",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Email",
          value: "email",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Habilitado",
          value: "habilitado",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Opções",
          value: "actions",
          sortable: false,
          class: "",
          cellClass: "sem-quebra",
        },
      ];
    },
    dadosTabela() {
      return this.registrosFiltrados;
    },
    btnsAcoes() {
      return [
        {
          tooltip: "Ver detalhes",
          cor: "grey darken-1",
          icone: "mdi-arrow-expand-all",
          acao: this.abrirDetalhes,
        },
        {
          tooltip: "Editar",
          cor: "blue darken-1",
          icone: "mdi-pencil",
          acao: this.irParaEdicao,
        },
        {
          tooltip: "Alterar Senha",
          cor: "teal",
          icone: "mdi-refresh-circle",
          acao: this.irParaAlteracaoSenha,
        },
        {
          tooltip: "Excluir",
          cor: "red darken-1",
          icone: "mdi-delete",
          acao: this.abrirConfirmacaoExclusao,
        },
      ];
    },
    itensFiltroEmpresa() {
      if (!this.permissaoMaster) return [];
      const itens = [];
      const empresas = this.empresas;
      return this.empresas.map((empresa) => {
        return {
          id: empresa.id,
          valor: empresa.razaoSocial,
        };
      });
    },
    registrosFiltrados() {
      let filtrados = [...this.usuarios];

      const filtrandoNome = !!this.filtros.nome;
      const filtrandoLogin = !!this.filtros.login;
      const filtrandoEmpresa = !!this.filtros.empresa;

      if (filtrandoNome) filtrados = this.filtrarNome(filtrados);
      if (filtrandoLogin) filtrados = this.filtrarLogin(filtrados);
      if (filtrandoEmpresa) filtrados = this.filtrarEmpresa(filtrados);

      return filtrados;
    },
  },
  methods: {
    ...mapActions({obterInformacoes: "actionObterInformacoes"}),
    obterDescricaoHabilitado(item) {
      return !!item.habilitado ? "SIM" : "NÃO";
    },
    obterClasseHabilitado(item) {
      return !!item.habilitado ? "green--text font-weight-bold" : "red--text font-weight-bold";
    },
    abrirDetalhes(item) {
      this.$refs.dialogDetalhes.dados = item;
      this.$refs.dialogDetalhes.exibir();
    },
    irParaEdicao(item) {
      const id = item.id;
      return this.navegar("usuariosEditar", {id});
    },
    irParaAlteracaoSenha(item) {
      const id = item.id;
      return this.navegar("usuariosAlterarSenha", {id});
    },
    abrirConfirmacaoExclusao(item) {
      this.idUsuarioExclusao = item.id;
      this.$refs.dialogConfirmacao.modelo = "excluirUsuario";
      this.$refs.dialogConfirmacao.exibir();
    },
    async excluirUsuario() {
      this.ativarLoading();
      try {
        await usuariosServices.excluir(this.idUsuarioExclusao);
        await this.obterInformacoes();
        this.notificacaoSucesso("Usuário Excluído");
      } catch (e) {
        this.interpretarErro(e);
      } finally {
        this.desativarLoading();
      }
    },
    filtrarNome(filtrados) {
      return filtrados.filter((f) => {
        return f.nome.toUpperCase().indexOf(this.filtros.nome.toUpperCase()) !== -1;
      });
    },
    filtrarLogin(filtrados) {
      return filtrados.filter((f) => {
        return f.usuario.toUpperCase().indexOf(this.filtros.login.toUpperCase()) !== -1;
      });
    },
    filtrarEmpresa(filtrados) {
      return filtrados.filter((f) => {
        return f.empresaId === Number(this.filtros.empresa);
      });
    },
    filtrar(obj) {
      this.filtros = obj;
    },
  },
  watch: {},
  created() {
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > thead > tr > th {
  color: var(--cor-primaria-100) !important;
}

.sombra {
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
</style>