<template>
	<v-dialog v-model="aberto" width="90%" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
		<v-card class="card-detalhes">
			<v-card-title class="titulo-card cor-principal">
				<div class="d-flex justify-space-between w-100">
					<div>Detalhes do Usuário</div>
					<v-btn icon dark @click="fechar()" color="var(--cor-primaria-100)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-card-title>

			<v-card-text class="mt-5" v-if="!!dados">
				<v-row>
					<v-col cols="12">
						<v-text-field
							:value="dados.nome"
							label="Nome do Usuário"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.usuario"
							label="Login do Usuário"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.email"
							label="Email"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.tipoDocumento"
							label="Tipo do Documento"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.documento"
							label="Documento"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.ddd"
							label="DDD"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.telefone"
							label="Telefone"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.admin ? 'Sim' : 'Não'"
							label="Permissão de Administrador"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dados.habilitado ? 'Sim' : 'Não'"
							label="Usuário Habilitado"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dataCadastro"
							label="Data do Cadastro"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							:value="dataAtualizacao"
							label="Última Atualização"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>

					<v-col cols="12" v-if="permissaoMaster">
						<v-text-field
							:value="empresa.razaoSocial"
							label="Empresa"
							outlined
							hide-details
							dense
							readonly
						/>
					</v-col>
				</v-row>
			</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="orange"
            @click="fechar">
          Fechar
        </v-btn>
      </v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from "moment";
import mixinGeral from "../../mixin/geral";

export default {
	name: "DialogDetalhes",
	mixins: [mixinGeral],
	components: {},
	data() {
		return {
			aberto: false,
			dados: null,
		};
	},
	computed: {
		backgroundInput() {
			return "grey lighten-3";
		},
		dataCadastro() {
			if (!this.dados) return "";
			const data = this.dados.criadoEm;
			return moment(data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
		},
		dataAtualizacao() {
			if (!this.dados) return "";
			const data = this.dados.atualizadoEm;
			return moment(data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
		},
		empresa() {
			if (!this.dados) return {};
			return this.buscarEmpresa(this.dados.empresaId);
		},
	},
	methods: {
		exibir() {
			this.aberto = true;
		},
		fechar() {
			this.aberto = false;
			this.dados = null;
		},
	},
	watch: {},
	created() {},
};
</script>

<style scoped>
.card-detalhes {
    border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
    font-size: 20px;
}

.cor-principal {
    color: var(--cor-primaria-100) !important;
}
</style>